@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.4s all;
  font-family: "Manrope", sans-serif;
  scroll-behavior: smooth;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  border: none;
  -webkit-text-fill-color: black;
  /* -webkit-box-shadow: 0 0 0 100px #f2f5fa inset; */
  transition: transparent 5000s ease-in-out 0s;
}
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  display: none !important;
}
.card_info > div:nth-child(even) {
  background-color: #093776;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: white !important;
}
.card_info > div:nth-child(even) div svg {
  /* display: none; */
  fill: white;
}
/* .card_info svg:nth-child(even){} */
.header {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.skiptranslate.goog-te-gadget {
  display: flex;
  align-items: center;
}
.skiptranslate.goog-te-gadget > span {
  display: none;
}

.skiptranslate.goog-te-gadget > span > a {
  display: flex;
}

.goog-gt-tt {
  display: none !important;
}
.VIpgJd-ZVi9od-ORHb {
  display: none !important;
}

/* .skiptranslate table {
  display: none;
} */

.skiptranslate.goog-te-gadget > div:first-child > select {
  outline: black;
  border: 1px solid rgb(148, 141, 141);
  border-radius: 4px;
}

.dash_webkit::-webkit-scrollbar {
  display: none;
}

.tradingview-widget-copyright {
  display: none !important;
}

.pagination > .active > a {
  background-color: #093776 !important;
  border: none !important;
}
table tbody tr:nth-child(odd) {
  background-color: #dadfe6;
}

table tbody tr:last-child {
  background-color: inherit;
}

input {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

@media only screen and (max-width: 768px) {
  .hero_view_height {
    height: 55vh;
  }
  .news_date {
    width: 46%;
  }
  td {
    font-size: 12px;
  }
  th {
    font-size: 12px;
  }
}
@media only screen and (max-width: 581px) {
  .news_date {
    width: 46%;
  }
}
@media only screen and (max-width: 480px) {
  .hero_view_height {
    height: 55vh;
  }
  .date_button {
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (max-width: 380px) {
  .hero_view_height {
    height: 68vh;
  }
  .news_date {
    width: 100%;
  }
  .date_button {
    flex-direction: row;
    gap: 10px;
  }
}
